<template>
  <div class="form-component">
    <label for="">
      Collection visibility
    </label>
    <div>
      Manage the visibility of the collection on the Collection List page and Recommendation widgets
    </div>
    <div>
      <v-checkbox hide-details="" @change="onChangePublishedScope" v-model="publishedScope" value="">
        <template v-slot:label>
          <div class="label-checkbox">
            Show on Collection List page
          </div>
        </template>
      </v-checkbox>
      <div class="input-desc ml-8">
        <span class="">
          This collection will be shown on the list page of Collections on your Online Store.
        </span>
      </div>
    </div>
    <div>
      <v-checkbox hide-details="" v-model="$parent.attributes.upsellWidget" value="">
        <template v-slot:label>
          <div class="label-checkbox">
            Show on Recommendation widgets
          </div>
        </template>
      </v-checkbox>
      <div class="input-desc ml-8">
        <span class=""> This collection will be shown on "Products from the same collections" widget </span>
      </div>
    </div>
    <div>
      <v-checkbox hide-details="" v-model="$parent.attributes.upsellOffer" value="">
        <template v-slot:label>
          <div class="label-checkbox">
            Shown on Upsell Offers
          </div>
        </template>
      </v-checkbox>
      <div class="input-desc ml-8">
        <span class=""> This collection will be shown on Upsell Offers </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      publishedScope: false,
    };
  },
  mounted() {
    if (!this.$parent.attributes.publishedScope) {
      this.publishedScope = true;
    }
  },
  methods: {
    onChangePublishedScope() {
      if (this.publishedScope) {
        this.$parent.attributes.publishedScope = '';
      } else {
        this.$parent.attributes.publishedScope = 'web';
      }
    },
  },
};
</script>

<style lang="scss">
.label-checkbox {
  font-weight: none !important;
  text-decoration: none;
}
</style>
