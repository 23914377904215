<template>
  <div>
    <v-radio-group v-if="!$parent.idItem" v-model="$parent.attributes.collectionType" column>
      <v-radio value="normal">
        <template v-slot:label>
          <div class="mt-3 d-flex flex-column fz-14">
            <div>Manual.</div>
            <div>Add products to this collection one by one.</div>
          </div>
        </template>
      </v-radio>
      <v-radio value="smart">
        <template v-slot:label>
          <div class="mt-7 d-flex flex-column fz-13">
            <div>Automated.</div>
            <div>
              Existing and future products that match the conditions you set will automatically be added to this
              collection.
            </div>
          </div>
        </template>
      </v-radio>
    </v-radio-group>
    <transition name="slide-value">
      <v-col v-if="$parent.attributes.collectionType === 'smart'" cols="12">
        <v-col v-if="!$parent.idItem" cols="12">
          <v-divider></v-divider>
        </v-col>
        <h3>Condition</h3>
        <div cols="12" class="d-flex align-center">
          <div>Products must match:</div>
          <v-radio-group class="ml-3" v-model="$parent.attributes.disjunctive" row>
            <v-radio :value="true" label="all conditions"> </v-radio>
            <v-radio label="any" :value="false"> </v-radio>
          </v-radio-group>
        </div>
        <v-row
          class="d-flex align-center"
          v-for="(item, index) in $parent.$v.attributes.rules.$each.$iter"
          :key="`item.column${index}`"
        >
          <v-col cols="12" sm="5">
            <v-select
              hide-details=""
              v-model="item.column.$model"
              :items="itemsSelectType"
              @change="changeColumnCondition(item.$model)"
              item-text="name"
              item-value="value"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="4">
            <v-select
              v-if="itemsSelectType.filter(i => i.value == item.column.$model).length > 0"
              hide-details=""
              v-model="item.relation.$model"
              :items="itemsSelectType.filter(i => i.value == item.column.$model)[0].rules"
              item-value="type"
            >
              <template v-slot:item="{ item }">
                <span class="ml-3">{{ item.name }}</span>
              </template>
              <template v-slot:selection="{ item }">
                <span class="ml-3">{{ item.name }} </span>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" sm="2" :class="!item.condition.required && item.condition.$dirty ? 'mt-5' : ''">
            <v-text-field
              :error-messages="!item.condition.required && item.condition.$dirty ? 'Required' : ''"
              v-model="item.condition.$model"
              hide-details=""
            ></v-text-field>
            <small class="mt-5 text-error">{{
              !item.condition.required && item.condition.$dirty ? 'Required' : ''
            }}</small>
          </v-col>

          <i v-if="index > 0" @click="removeRules(index)" class="pointer far fa-trash-alt"></i>
        </v-row>
        <v-row no-gutters class="my-2">
          <v-btn outlined color="primary" @click="addRules">Add another condition</v-btn>
        </v-row>
      </v-col>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    const stringRules = [
      {
        type: 'equal',
        name: 'is equal to',
      },
      {
        type: 'notEqual',
        name: 'is not equal to',
      },
      {
        type: 'contain',
        name: 'contains',
      },
      {
        type: 'notContain',
        name: `doesn't contain`,
      },
      {
        type: 'startWith',
        name: 'starts with',
      },
      {
        type: 'endWith',
        name: 'ends with',
      },
    ];
    const numberRules = [
      {
        type: 'equal',
        name: 'is equal to',
      },
      {
        type: 'notEqual',
        name: 'is not equal to',
      },
      {
        type: 'greaterThan',
        name: 'is greater than',
      },
      {
        type: 'lessThan',
        name: 'is less than',
      },
    ];
    const arrayRules = [
      {
        type: 'contain',
        name: 'is equal to',
      },
    ];
    return {
      itemsSelectType: [
        {
          name: 'Product title',
          value: 'title',
          rules: stringRules,
        },
        {
          name: 'Product type',
          value: 'productType',
          rules: stringRules,
        },
        {
          name: 'Product tags',
          value: 'tags',
          rules: arrayRules,
        },
        {
          name: 'Product vendor',
          value: 'vendor',
          rules: stringRules,
        },
        // {
        //   name: 'Product price',
        //   value: 'variant.price',
        //   rules: numberRules,
        // },
        // {
        //   name: 'Compare at price',
        //   value: 'variant.compareAtPrice',
        //   rules: numberRules,
        // },
        // {
        //   name: 'Weight',
        //   value: 'variant.weight',
        //   rules: numberRules,
        // },
        // {
        //   name: 'Inventory stock',
        //   value: 'variant.inventoryQuantity',
        //   rules: numberRules,
        // },
        // {
        //   name: 'Variant title',
        //   value: 'variant.title',
        //   rules: stringRules,
        // },
        //Variant title
      ],
      itemsRelation: [
        {
          name: 'Contains',
          value: 'contain',
        },
      ],
    };
  },
  methods: {
    changeColumnCondition(it) {
      let findRules = this.itemsSelectType.find(item => item.value === it.column);
      if (findRules) {
        let checkRuleCurrent = findRules.rules.find(r => r.type === it.relation);
        if (!checkRuleCurrent) {
          it.relation = findRules.rules[0].type;
        }
      }
      // let item = this.$parent.attributes.rules.find(i => i.column === e);
      // console.log(item, e, i);
      // item.relation = '';
    },
    addRules() {
      this.$parent.attributes.rules.push({ column: 'tags', relation: '', condition: null });
    },
    removeRules(index) {
      this.$parent.attributes.rules.splice(index, 1);
    },
    getOperatorLabel(field, operator) {
      switch (operator) {
        case '=':
          return 'Is Equal to';
        case '!=':
          return 'Is Not Equal to';
        case '>':
          return 'Is Greater than';
        case '>=':
          return 'Is Greater than or Equal to';
        case '<':
          return 'Is Less than';
        case '<=':
          return 'Is Less than or Equal to';
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
